var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_c('template',{slot:"headerRight"},[_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:(
        ("" + (_vm.isMiddleMan ? 'middleman-vehicle:add' : 'supplierVehicle:save'))
      ),expression:"\n        `${isMiddleMan ? 'middleman-vehicle:add' : 'supplierVehicle:save'}`\n      "}],attrs:{"type":"primary"},on:{"click":function($event){_vm.addVisible = true
        _vm.currentItem = null}}},[_vm._v(" 添加车辆 ")])],1),_c('template',{slot:"headerLeft"},[_c('el-form',{ref:"filtersForm",attrs:{"inline":"","model":_vm.params}},[_c('el-form-item',{attrs:{"label":"车辆号","prop":"licensePlate"}},[_c('el-input',{attrs:{"placeholder":"车辆号"},model:{value:(_vm.params.licensePlate),callback:function ($$v) {_vm.$set(_vm.params, "licensePlate", $$v)},expression:"params.licensePlate"}})],1)],1)],1),_c('div',{staticClass:"mainWrap"},[_c('div',{staticClass:"leftGroup"},[_c('h3',{staticStyle:{"margin-bottom":"6px"}},[_vm._v("车辆分组")]),_c('el-tree',{attrs:{"default-expand-all":"","highlight-current":true,"current-node-key":1,"data":_vm.treeData,"node-key":"id"},on:{"node-click":_vm.nodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var node = ref.node;
        var data = ref.data;
return [_c('span',{staticClass:"custom-tree-node"},[_c('span',[_vm._v(_vm._s(node.label))]),(data.showGroup)?_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:(
                ("" + (_vm.isMiddleMan
                    ? 'middleman-vehicle-grouping:save'
                    : 'vehicle-grouping:add'))
              ),expression:"\n                `${\n                  isMiddleMan\n                    ? 'middleman-vehicle-grouping:save'\n                    : 'vehicle-grouping:add'\n                }`\n              "}],staticClass:"addGroup",attrs:{"size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.addGroup.apply(null, arguments)}}},[_vm._v("添加分组")]):_vm._e(),(data.showBtn)?_c('span',{staticClass:"operate"},[_c('i',{directives:[{name:"auth",rawName:"v-auth",value:(
                  ("" + (_vm.isMiddleMan
                      ? 'middleman-vehicle-grouping:delete'
                      : 'vehicle-grouping:delete'))
                ),expression:"\n                  `${\n                    isMiddleMan\n                      ? 'middleman-vehicle-grouping:delete'\n                      : 'vehicle-grouping:delete'\n                  }`\n                "}],staticClass:"el-icon-delete",staticStyle:{"color":"#f56c6c"},on:{"click":function($event){$event.stopPropagation();return _vm.treeDelHandler(data.id)}}}),_c('i',{directives:[{name:"auth",rawName:"v-auth",value:(
                  ("" + (_vm.isMiddleMan
                      ? 'middleman-vehicle-grouping:update'
                      : 'vehicle-grouping:update'))
                ),expression:"\n                  `${\n                    isMiddleMan\n                      ? 'middleman-vehicle-grouping:update'\n                      : 'vehicle-grouping:update'\n                  }`\n                "}],staticClass:"el-icon-edit",staticStyle:{"color":"#409eff","margin-left":"4px"},on:{"click":function($event){$event.stopPropagation();return _vm.treeEditHandler(data)}}})]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"table-wrap"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData,"height":"100%"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"licensePlate","label":"车牌号"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"vehicleName","label":"车辆名称"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"groupName","label":"车辆分组"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"remark","label":"备注"}}),(!_vm.isMiddleMan)?_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"groupManageNum","min-width":"120","label":"关联门店分组数"}}):_vm._e(),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"modifyTime","label":"修改时间"}}),_c('el-table-column',{attrs:{"width":"220","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [(!_vm.isMiddleMan)?_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:('supplierVehicle:associated'),expression:"'supplierVehicle:associated'"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.storeGroup(row)}}},[_vm._v("关联门店分组")]):_vm._e(),_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:(
                ("" + (_vm.isMiddleMan
                    ? 'middleman-vehicle:update'
                    : 'supplierVehicle:update'))
              ),expression:"\n                `${\n                  isMiddleMan\n                    ? 'middleman-vehicle:update'\n                    : 'supplierVehicle:update'\n                }`\n              "}],attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.editHandler(row)}}},[_vm._v("编辑")]),_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:(
                ("" + (_vm.isMiddleMan
                    ? 'middleman-vehicle:delete'
                    : 'supplierVehicle:delete'))
              ),expression:"\n                `${\n                  isMiddleMan\n                    ? 'middleman-vehicle:delete'\n                    : 'supplierVehicle:delete'\n                }`\n              "}],attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.delHandler(row.id)}}},[_vm._v("删除")])]}}])})],1)],1)]),_c('pagination',{attrs:{"slot":"pagination","totalCount":_vm.totalCount,"page-size":_vm.params.limit,"page":_vm.params.page},on:{"update:pageSize":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page-size":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"change":_vm.getData},slot:"pagination"}),(_vm.storeGroupVisible)?_c('StoreGroup',{attrs:{"visible":_vm.storeGroupVisible,"current-item":_vm.currentItem},on:{"getData":function($event){return _vm.getData(true)},"update:visible":function($event){_vm.storeGroupVisible=$event}}}):_vm._e(),(_vm.addVisible)?_c('AddModal',{attrs:{"visible":_vm.addVisible,"groupList":_vm.groupList,"current-item":_vm.currentItem},on:{"getData":function($event){return _vm.getData(true)},"update:visible":function($event){_vm.addVisible=$event}}}):_vm._e(),(_vm.groupVisible)?_c('AddGroupModal',{attrs:{"visible":_vm.groupVisible,"current-item":_vm.currentItem},on:{"getCarGroupList":function($event){return _vm.getCarGroupList()},"update:visible":function($event){_vm.groupVisible=$event}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }