<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="关联门店分组"
    :visible.sync="show"
    width="500px"
  >
    <div>
      <el-table
        ref="selectTable"
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        :max-height="400"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column show-overflow-tooltip prop="id" label="分组ID" />
        <el-table-column show-overflow-tooltip prop="name" label="分组名称" />
      </el-table>
      <!-- <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      /> -->
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false"> 关闭 </el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      totalCount: 0,
      tableData: [],
      multipleSelection: []
    }
  },
  async created() {
    this.getData()
  },

  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },
    async submit() {
      this.loading = true
      try {
        await this.$api.carManage.relateGroup({
          id: this.currentItem.id,
          groupManageIdList: this.multipleSelection
        })
        this.$message.success('保存成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getData() {
      this.loading = true

      try {
        const res = await this.$api.carManage.getCarGroupAllList({
          id: this.currentItem.id
        })

        this.tableData = res.list

        this.$nextTick(() => {
          res.list
            .filter(_ => _.exist == 1)
            .forEach((row, index) => {
              this.$refs.selectTable.toggleRowSelection(this.tableData[index])
            })
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tips {
  color: brown;
  margin-left: 33px;
}
</style>
