<template>
  <page>
    <template slot="headerRight">
      <el-button
        v-auth="
          `${isMiddleMan ? 'middleman-vehicle:add' : 'supplierVehicle:save'}`
        "
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加车辆
      </el-button>
      <!-- <el-button @click="delHandler(false)" :disabled="!multipleSelection.length" type="danger"> 批量删除 </el-button> -->
    </template>

    <template slot="headerLeft">
      <el-form ref="filtersForm" inline :model="params">
        <el-form-item label="车辆号" prop="licensePlate">
          <el-input
            v-model="params.licensePlate"
            placeholder="车辆号"
          ></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="mainWrap">
      <div class="leftGroup">
        <h3 style="margin-bottom: 6px">车辆分组</h3>
        <el-tree
          @node-click="nodeClick"
          default-expand-all
          :highlight-current="true"
          :current-node-key="1"
          :data="treeData"
          node-key="id"
        >
          <template #default="{ node, data }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>

              <el-button
                v-auth="
                  `${
                    isMiddleMan
                      ? 'middleman-vehicle-grouping:save'
                      : 'vehicle-grouping:add'
                  }`
                "
                v-if="data.showGroup"
                class="addGroup"
                size="mini"
                @click.stop="addGroup"
                >添加分组</el-button
              >
              <span class="operate" v-if="data.showBtn">
                <i
                  v-auth="
                    `${
                      isMiddleMan
                        ? 'middleman-vehicle-grouping:delete'
                        : 'vehicle-grouping:delete'
                    }`
                  "
                  @click.stop="treeDelHandler(data.id)"
                  style="color: #f56c6c"
                  class="el-icon-delete"
                ></i>
                <i
                  v-auth="
                    `${
                      isMiddleMan
                        ? 'middleman-vehicle-grouping:update'
                        : 'vehicle-grouping:update'
                    }`
                  "
                  @click.stop="treeEditHandler(data)"
                  style="color: #409eff; margin-left: 4px"
                  class="el-icon-edit"
                ></i>
              </span>
            </span>
          </template>
        </el-tree>
      </div>
      <div class="table-wrap">
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData"
          v-loading="loading"
          height="100%"
        >
          <!-- <el-table-column  type="selection" width="55" /> -->
          <el-table-column
            show-overflow-tooltip
            prop="licensePlate"
            label="车牌号"
          />
          <el-table-column
            show-overflow-tooltip
            prop="vehicleName"
            label="车辆名称"
          />
          <el-table-column
            show-overflow-tooltip
            prop="groupName"
            label="车辆分组"
          />
          <el-table-column show-overflow-tooltip prop="remark" label="备注" />
          <el-table-column
            v-if="!isMiddleMan"
            show-overflow-tooltip
            prop="groupManageNum"
            min-width="120"
            label="关联门店分组数"
          />
          <el-table-column
            show-overflow-tooltip
            prop="modifyTime"
            label="修改时间"
          />
          <el-table-column width="220" label="操作">
            <template slot-scope="{ row }">
              <el-button
                v-auth="'supplierVehicle:associated'"
                v-if="!isMiddleMan"
                type="text"
                @click="storeGroup(row)"
                >关联门店分组</el-button
              >
              <el-button
                v-auth="
                  `${
                    isMiddleMan
                      ? 'middleman-vehicle:update'
                      : 'supplierVehicle:update'
                  }`
                "
                @click="editHandler(row)"
                type="text"
                size="mini"
                >编辑</el-button
              >
              <el-button
                v-auth="
                  `${
                    isMiddleMan
                      ? 'middleman-vehicle:delete'
                      : 'supplierVehicle:delete'
                  }`
                "
                @click="delHandler(row.id)"
                type="text"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <StoreGroup
      v-if="storeGroupVisible"
      @getData="getData(true)"
      :visible.sync="storeGroupVisible"
      :current-item="currentItem"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :groupList="groupList"
      :current-item="currentItem"
    />

    <AddGroupModal
      v-if="groupVisible"
      @getCarGroupList="getCarGroupList()"
      :visible.sync="groupVisible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal.vue'
import AddGroupModal from './components/AddGroupModal.vue'
import StoreGroup from './components/StoreGroup.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      addVisible: false,
      groupVisible: false,
      storeGroupVisible: false,
      currentItem: null,
      treeData: [
        {
          label: '全部分组',
          id: 1,
          showGroup: true,
          children: []
        }
      ],
      tableData: [],
      memberList: [],
      multipleSelection: [],
      groupList: [],
      params: {
        limit: 20,
        page: 1,
        licensePlate: ''
      },
      totalCount: 0
    }
  },
  created() {
    this.getCarGroupList()
    this.getData()
  },
  components: {
    AddModal,
    AddGroupModal,
    StoreGroup
  },
  computed: {
    isMiddleMan() {
      return localStorage.getItem('userType') == 'MIDDLEMAN'
    }
  },
  methods: {
    nodeClick(data) {
      this.params.groupId = data.id == 1 ? undefined : data.id
      this.getData()
    },
    addGroup() {
      this.groupVisible = true
      this.currentItem = null
    },
    treeEditHandler(nodeData) {
      this.groupVisible = true
      this.currentItem = nodeData
    },
    async treeDelHandler(id) {
      try {
        await this.$confirm(
          `删除分组后，该分组下的车辆将关联到未分组，确定删除？`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.carManage.delCarGroup({ id })
          this.$message.success('删除成功！')
          this.getCarGroupList()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async operate(row) {
      try {
        await this.$confirm(
          `是否要${row.status == 1 ? '停用' : '启用'}?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.carManage.updateMemberInfo({
            id: row.id,
            status: row.status == 1 ? 2 : 1
          })

          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getCarGroupList() {
      try {
        const res = await this.$api.carManage.getCarGroupList()

        let group = res.group || []

        group.push({
          groupName: '未分组',
          id: ''
        })

        this.groupList = res.group.map(item => ({
          label: item.groupName,
          value: item.id
        }))

        this.treeData[0].children = res.group.map(item => ({
          label: item.groupName,
          id: item.id,
          showBtn: item.groupName !== '未分组'
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.carManage.getCarList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            item.groupName = item.groupName || '未分组'

            return item
          })
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async delHandler(id) {
      try {
        await this.$confirm(
          '车辆信息删除后，将解除门店分组与车辆的关联关系，删除后不会影响已发货的订单物流信息，是否确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true

          await this.$api.carManage.delCar({
            id
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    },
    storeGroup(row) {
      this.currentItem = row
      this.storeGroupVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.mainWrap {
  display: flex;
  flex: 1;
  .leftGroup {
    padding: 6px;
    width: 250px;
    border: 1px solid #eee;

    .addGroup {
      padding: 3px 8px !important;
    }
  }

  .operate i {
    font-size: 16px;
  }

  ::v-deep .custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
  }
}
</style>
